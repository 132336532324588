import { VueLd } from 'vue-ld'; // Plugin to integrate Launch Darkly into Vue apps
import Vue from 'vue';
import { developmentFlagValues, defaultFlagValues } from '@/utils/featureFlags.js';

import { config } from '@/utils/config.js';

/**
 * Launch darkly configuration functions including initialization and identify calls for when a user changes.
 *
 * @exports launchDarklyConfig
 * @category Utils
 */

// Array of environments in which we want to stubFlag values and instead default to "developmentFlagValues"
const shouldStubFlags = config.featureFlagsDisabled(['development', 'test', 'ci']);

/**
 * Adds checkFlags method to the global LaunchDarkly object to allow us to declare default flags in case
 * a flag cannot be found or a connection to LaunchDarkly cannot be established.
 *
 * @param {string} flagName
 * @returns {number|string|boolean}
 */
export const checkFlags = (flagName) => {
  const { ready, flags } = Vue.prototype.$ld;
  // Unable to call hasOwnProperty on a global variable, so object.keys used instead
  const ldFlagNameExists = flags && Object.keys(flags).includes(flagName);
  const defaultFlagNameExists = Object.prototype.hasOwnProperty.call(defaultFlagValues, flagName);

  if (shouldStubFlags || (ready && ldFlagNameExists)) {
    return flags[flagName];
  }

  // If a flag cannot be found or a connection to LaunchDarkly cannot be established and there is
  // no defaultFlagValue in the utility file, then the feature flag's value falls back to false.
  // Whether or not we want it to be true or false by default is a conversation for the team
  return defaultFlagNameExists ? defaultFlagValues[flagName] : false;
};

/**
 * Initializes Launch Darkly with an anonymous user and the clientSideId that corresponds with the
 * the current environment. Also passes any custom options to the Launch Darkly client.
 * Leverages dashHudsons Vue-Ld Vue plugin to interface with Launch Darkly: https://github.com/dashhudson/vue-ld.
 *
 * @param {object} configuration
 * @param {string} configuration.clientSideId
 * @param {object} configuration.options
 * @param {object} configuration.user
 */
export const initializeLaunchDarkly = ({ clientSideId, options, user }) => {
  Vue.use(VueLd, {
    clientSideId,
    user,
    options,
    // If flagsStub is provided, the ldClient will not be initialized and $ld.flags will be set to the provided stub (developmentFlagValues)
    flagsStub: shouldStubFlags ? developmentFlagValues : undefined,
  });
  Vue.prototype.$ld.checkFlags = checkFlags;
};

/**
 * The identify method notifies Launch Darkly that the user has changed and provides their related userInfo so that
 * Launch Darkly can conditionally render flags based on the user.
 *
 * @param {object} configuration
 * @param {string} configuration.options.hash
 * @param {object} configuration.user
 */
export const identifyLaunchDarklyUser = (configuration) => {
  Vue.prototype.$ld.identify({
    hash: configuration.options?.hash,
    newUser: configuration.user,
  });
};

/**
 * Parse the LD-CONFIGURATION header on every Core API response as a JSON object.
 *
 * @param {object} response
 * @param {object} response.headers
 * @returns {object}
 */
export const parseLaunchDarklyConfiguration = ({ headers = {} }) => JSON.parse(headers['ld-configuration']);
